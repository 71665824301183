<template>
    <el-checkbox-group v-model="selected" :min="required ? 1 : 0" @change="submit">
        <el-row>
            <el-col>
                <div v-for="allergen in allergens" :key="allergen.uuid">
                    <el-checkbox :label="allergen.uuid">{{allergen.name}}</el-checkbox>
                </div>
            </el-col>
        </el-row>
    </el-checkbox-group>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        name: "AllergenSelector",
        created() {
            if (this.value.length) {
                if (this.allergens.length < 1) {
                    this.$store.dispatch('loadAllergens').then(() => {
                        this.fillSelectedFromUuids(this.value)
                    })
                } else {
                    this.fillSelectedFromUuids(this.value)
                }
            }
        },
        props: {
            value: {
                type: Array,
                default: function () {
                    return []
                }
            },
            required: {
                type: Boolean,
            }
        },
        data() {
            return {
                selected: [],
            }
        },
        computed: {
            ...mapGetters([
                'allergens'
            ])
        },
        methods: {
            fillSelectedFromUuids(uuidArray) {
                if (Array.isArray(uuidArray)) {
                    this.selected = uuidArray.filter(uuid => this.allergens.some(allergen => allergen.uuid === uuid))
                } else {
                    this.selected = []
                }
            },
            submit() {
                this.$emit('input', this.selected)
            },
        },
        watch: {
            value: function () {
                this.fillSelectedFromUuids(this.value)
            },
        },
    }
</script>

<style scoped>

</style>
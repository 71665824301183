<template>
    <div v-if="tagName">{{ tagName }}</div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        name: "Tag",
        props: {
            identifier: {
                type: String,
                required: true,
            },
        },
        computed: {
            ...mapGetters([
                'tagById'
            ]),
            tagName() {
                let tag = this.tagById(this.identifier)
                return tag ? tag.name : null
            },
        },
    }
</script>

<style scoped>

</style>
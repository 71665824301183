<template>
    <div>
        <el-row>
            <el-col class="d-flex flex-row justify-content-center align-items-center">
                <el-input
                    @keyup.enter.native="addItem"
                    v-model="item"
                    :placeholder="placeholder">
                </el-input>

                <i class="ml-2 el-icon-circle-plus icon-gray" @click="addItem"></i>
            </el-col>
        </el-row>

        <el-row>
            <el-col>
                <div v-for="(value, key) in items" :key="key" class="item ml-3 mt-2">
                    <i class="el-icon-close icon-gray" @click="removeItem(value)"></i> {{value}}
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: "ItemListEditable",
        props: {
            value: Array,
            placeholder: String
        },
        data() {
            return {
                item: null,
                items: []
            }
        },
        mounted() {
            this.items = this.value;
        },
        methods: {
            addItem() {
                if (this.item) {
                    this.items.push(this.item.toUpperCaseFirst());
                    this.item = null;
                    this.notifyChange(this.items);
                }
            },
            removeItem(value) {
                this.items = this.items.filter(item => item !== value);
                this.notifyChange(this.items);
            },
            notifyChange(items) {
                this.$emit('input', items);
            }
        },
        watch: {
            value: function () {
                this.items = this.value;
            }
        }
    }
</script>

<style scoped>
    .item {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
    }

    i {
        cursor: pointer;
    }

    i.el-icon-circle-plus {
        font-size: 20px;
    }
</style>

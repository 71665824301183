<template>
    <div>
        <el-row>
            <el-col>
                <image-slider
                    :image-urls="base64Images"
                    :editor="true"
                    :error="showRequiredError"
                    @delete="deleteImage"></image-slider>
            </el-col>
        </el-row>

        <el-row class="mt-4">
            <el-col class="d-flex flex-row align-items-center">
                <div>
                    <input ref="file" type="file" @change="onFileChange" class="d-none"/>

                    <el-button type="success" round :loading="isLoading" @click="uploadImage">
                        Subir Imagen <i class="el-icon-upload"></i>
                    </el-button>
                </div>

                <div v-if="this.showRequiredError" class="ml-3 error">
                    Campo obligatorio
                </div>
            </el-col>
        </el-row>

        <el-row class="mt-4">
            <el-col>
                <i class="el-icon-info icon-gray"></i> Selecciona una fotografía horizontal.
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import {messages, dialogs} from '../../constants/text-constants';
    import ImageSlider from "../../components/ImageSlider";

    export default {
        name: "ImageUploadBase64Multiple",
        components: {
            ImageSlider
        },
        props: {
            value: {
                type: Array,
                default: function () {
                    return []
                }
            },
            doLoading: {
                type: Boolean
            },
            showRequiredError: Boolean
        },
        data() {
            return {
                base64Images: [],
                loading: false
            }
        },
        created() {
            this.base64Images = this.value;
        },
        computed: {
            isLoading() {
                return this.doLoading || this.loading;
            }
        },
        methods: {
            uploadImage() {
                if (this.base64Images.length === 3) {
                    this.showMaxImagesMessage()
                } else {
                    this.openImageSelector()
                }
            },
            showMaxImagesMessage() {
                this.$alert(dialogs.image.upload.maximum.text, {
                    confirmButtonText: dialogs.image.upload.maximum.confirm
                }).catch(() => {
                });
            },
            openImageSelector() {
                this.$refs.file.click()
            },
            onFileChange(e) {
                let file = e.target.files[0];
                if (file) {
                    this.loading = true;
                    let img = new Image();
                    img.src = URL.createObjectURL(file);
                    img.onload = () => {
                        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
                            this.uploadError(messages.image.upload.format.error);
                        } else if (img.naturalWidth < 800 || img.naturalHeight < 600) {
                            this.uploadError(messages.image.upload.resolution.error);
                        } else if (file.size / 1024 / 1024 > 5) {
                            this.uploadError(messages.image.upload.size.error);
                        } else {
                            let reader = new FileReader();
                            reader.onloadend = () => {
                                this.base64Images.push(reader.result);
                                this.emitInput();
                                this.$refs.file.value = null;
                            };
                            reader.readAsDataURL(file);
                        }
                        this.loading = false;
                    };
                }
            },
            uploadError(message) {
                this.$message.error(message);
            },
            deleteImage(imageBase64) {
                this.base64Images = this.base64Images.filter(base64 => base64 !== imageBase64);
                this.emitInput();
            },
            emitInput() {
                this.$emit('input', this.base64Images)
            }
        },
        watch: {
            value: function () {
                this.base64Images = this.value;
            }
        }
    }
</script>

<style scoped>
    .error {
        font-style: italic;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #D89172;
    }
</style>

<template>
    <div v-if="allergenName">{{ allergenName }}</div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        name: "Allergen",
        props: {
            identifier: {
                type: String,
                required: true,
            },
        },
        computed: {
            ...mapGetters([
                'allergenById'
            ]),
            allergenName() {
                let allergen = this.allergenById(this.identifier)
                return allergen ? allergen.name : null
            },
        },
    }
</script>

<style scoped>

</style>
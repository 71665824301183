<template>
    <el-row type="flex" :gutter="40" justify="end">
        <el-col :span="8">
            <el-row v-if="Array.isArray(cakeCustomizations) && cakeCustomizations.length">
                <el-col>
                    <div class="section-title">PRECIOS EXTRAS*</div>
                </el-col>
            </el-row>

            <el-row class="mb-4" v-if="Array.isArray(cakeCustomizations) && cakeCustomizations.length">
                <el-col :class="[{ 'module-disabled': byBudget }, 'bg-light', 'border', 'border-gray', 'rounded', 'p-4', 'mt-2']">
                    <el-form
                        size="medium"
                        label-position="top"
                        :disabled="byBudget"
                        @submit.prevent>
                        <el-row v-for="(customization, key) in cakeCustomizations" :key="key">
                            <el-col>
                                <el-form-item class="mb-2 mx-2">
                                    <template slot="label">
                                        {{customization.name}}
                                    </template>

                                    <div class="d-flex flex-row justify-content-start">
                                        <currency-input
                                            v-model="customization.price"
                                            placeholder="Ej. 5"
                                            classes="max-width-small"></currency-input>

                                        <span class="ml-2">€</span>
                                    </div>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row class="mt-3 mx-2">
                            <el-col class="info-legend">
                                <i class="el-icon-info icon-gray"></i> Introducir 0 para personalización gratuita
                            </el-col>
                        </el-row>

                        <el-row v-if="extrasModuleError" class="mt-3 mx-2">
                            <el-col class="module-error">
                                hay extras sin precio definido
                            </el-col>
                        </el-row>
                    </el-form>
                </el-col>
            </el-row>

            <el-row v-show="hasCustomizations">
                <el-col>
                    <div class="section-title">PRECIOS PERSONALIZACIONES*</div>
                </el-col>
            </el-row>

            <el-row v-show="hasCustomizations">
                <el-col :class="[{ 'module-disabled': byBudget }, 'bg-light', 'border', 'border-gray', 'rounded', 'p-4', 'mt-2']">
                    <el-form
                        size="medium"
                        label-position="top"
                        :disabled="byBudget"
                        @submit.prevent>

                        <el-row v-show="hasFillings" class="mb-2">
                            <span>RELLENOS</span>
                        </el-row>

                        <el-row v-show="hasFillings" v-for="(filling, key) in cakeFillings" :key="'filling-'+key">
                            <el-col>
                                <el-form-item class="mb-2 mx-2">
                                    <template slot="label">
                                        {{filling.name}}
                                    </template>

                                    <div class="d-flex flex-row justify-content-start">
                                        <currency-input
                                            v-model="filling.price"
                                            placeholder="Ej. 5"
                                            classes="max-width-small"></currency-input>

                                        <span class="ml-2">€</span>
                                    </div>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row v-show="hasFlours" class="mt-4 mb-2">
                            <span>HARINAS - BIZCOCHOS</span>
                        </el-row>

                        <el-row v-show="hasFlours" v-for="(flour, key) in cakeFlours" :key="'flour-'+key">
                            <el-col>
                                <el-form-item class="mb-2 mx-2">
                                    <template slot="label">
                                        {{flour.name}}
                                    </template>

                                    <div class="d-flex flex-row justify-content-start">
                                        <currency-input
                                            v-model="flour.price"
                                            placeholder="Ej. 5"
                                            classes="max-width-small"></currency-input>

                                        <span class="ml-2">€</span>
                                    </div>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row v-show="hasToppings" class="mt-4 mb-2">
                            <span>COBERTURAS</span>
                        </el-row>

                        <el-row v-show="hasToppings" v-for="(topping, key) in cakeToppings" :key="'topping-'+key">
                            <el-col>
                                <el-form-item class="mb-2 mx-2">
                                    <template slot="label">
                                        {{topping.name}}
                                    </template>

                                    <div class="d-flex flex-row justify-content-start">
                                        <currency-input
                                            v-model="topping.price"
                                            placeholder="Ej. 5"
                                            classes="max-width-small"></currency-input>

                                        <span class="ml-2">€</span>
                                    </div>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row v-show="hasSweeteners" class="mt-4 mb-2">
                          <span>ENDULZANTES</span>
                        </el-row>

                        <el-row v-show="hasSweeteners" v-for="(sweetener, key) in cakeSweeteners" :key="'sweetener-'+key">
                          <el-col>
                            <el-form-item class="mb-2 mx-2">
                              <template slot="label">
                                {{sweetener.name}}
                              </template>

                              <div class="d-flex flex-row justify-content-start">
                                <currency-input
                                    v-model="sweetener.price"
                                    placeholder="Ej. 5"
                                    classes="max-width-small"></currency-input>

                                <span class="ml-2">€</span>
                              </div>
                            </el-form-item>
                          </el-col>
                        </el-row>

                        <el-row class="mt-3 mx-2">
                            <el-col class="info-legend">
                                <i class="el-icon-info icon-gray"></i> Introducir 0 para personalización base
                            </el-col>
                        </el-row>

                        <el-row v-if="customizationsModuleError" class="mt-3 mx-2">
                            <el-col class="module-error">
                                hay personalizaciones sin precio definido o sin personalización base
                            </el-col>
                        </el-row>
                    </el-form>
                </el-col>
            </el-row>
        </el-col>

        <el-col :span="8">
            <el-row>
                <el-col>
                    <div class="section-title">PRECIOS RACIONES*</div>
                </el-col>
            </el-row>

            <el-row>
                <el-col :class="[{ 'module-disabled': byBudget }, 'bg-light', 'border', 'border-gray', 'rounded', 'p-4', 'mt-2']">
                    <el-form
                        ref="portionPriceForm"
                        :model="portion"
                        :rules="portionRules"
                        size="medium"
                        :status-icon="true"
                        :inline-message="true"
                        :hide-required-asterisk="true"
                        label-position="top"
                        :disabled="byBudget"
                        @submit.prevent>
                        <el-row>
                            <el-col>
                                <el-form-item prop="portions" class="mb-2 mx-2">
                                    <template slot="label">
                                        Nº Raciones
                                    </template>

                                    <div class="d-flex flex-row justify-content-start">
                                        <el-input
                                            v-model.number="portion.portions"
                                            placeholder="Ej. 12"
                                            class="max-width-small"></el-input>

                                        <span class="ml-2">Personas</span>
                                    </div>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row>
                            <el-col>
                                <el-form-item prop="price" class="mb-2 mx-2">
                                    <template slot="label">
                                        Precio
                                    </template>

                                    <div class="d-flex flex-row justify-content-start">
                                        <currency-input
                                            v-model="portion.price"
                                            placeholder="Ej. 36"
                                            classes="max-width-small"></currency-input>

                                        <span class="ml-2">€</span>
                                    </div>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row>
                            <el-col>
                                <el-form-item class="mb-2 mt-3 mx-2">
                                    <el-button type="success" @click="addPortion">Añadir</el-button>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row v-if="portionsModuleError" class="mt-3 mx-2">
                            <el-col class="module-error">
                                debe añadir al menos una porción
                            </el-col>
                        </el-row>
                    </el-form>
                </el-col>
            </el-row>

            <el-row class="mt-4">
                <el-col>
                    <div class="section-title">PRECIOS ENVIOS*</div>
                </el-col>
            </el-row>

            <el-row>
                <el-col :class="[{ 'module-disabled': byBudget }, 'bg-light', 'border', 'border-gray', 'rounded', 'p-4', 'mt-2']">
                    <el-form
                        ref="shippingPriceForm"
                        :model="cakeShipping"
                        :rules="shippingRules"
                        size="medium"
                        :status-icon="true"
                        :inline-message="true"
                        :hide-required-asterisk="true"
                        label-position="top"
                        :disabled="byBudget"
                        @submit.prevent>

                        <el-row>
                          <el-col>
                            <div class="overview">
                              <el-switch
                                  v-model="notAvailableForPickup"
                                  @change="changeNotAvailableForPickup"
                                  active-color="#C45F24">
                              </el-switch>
                              <span class="ml-2">No disponible recogida</span>
                            </div>
                          </el-col>
                        </el-row>

                        <el-row class="mt-4">
                            <el-col>
                                <el-form-item prop="type" class="mb-2 mx-2">
                                    <template slot="label">
                                        Tipo de Envío
                                    </template>

                                    <shipping-type-selector
                                        v-model="cakeShipping.type"
                                        :pickup="!notAvailableForPickup"
                                        placeholder="Ej. 12"
                                        class="max-width-small"></shipping-type-selector>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row v-if="cakeShipping.type !== 'IN_SHOP'">
                            <el-col>
                                <el-form-item prop="price" class="mb-2 mx-2">
                                    <template slot="label">
                                        Precio
                                    </template>

                                    <div class="d-flex flex-row justify-content-start">
                                        <currency-input
                                            v-model="cakeShipping.price"
                                            placeholder="Ej. 30"
                                            classes="max-width-small"></currency-input>

                                        <span class="ml-2">€</span>
                                    </div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-col>
            </el-row>
        </el-col>

        <el-col :span="8">
            <el-row v-if="purchasable" class="mb-4">
                <el-col>
                    <div class="overview">
                        <el-switch
                            v-model="byBudget"
                            active-color="#C45F24"
                            class="mr-2">
                        </el-switch>
                        Creación  bajo presupuesto
                    </div>
                </el-col>
            </el-row>

            <el-row v-if="purchasable" class="mb-4">
              <el-col>
                <div class="overview">
                  <el-switch
                      v-model="allowsPayOnDelivery"
                      active-color="#C45F24"
                      class="mr-2">
                  </el-switch>
                  Permite pagar a la entrega
                </div>
              </el-col>
            </el-row>

            <el-row>
                <el-col>
                    <div class="section-title">PRECIOS FINALES</div>
                </el-col>
            </el-row>

            <el-row class="mt-3 font-weight-bold">
                <el-col>
                    <div class="mb-2 price-title">
                        Precio Mínimo
                    </div>

                    <div>
                        <template v-if="byBudget">
                            <currency-input
                                v-model="minimumPrice"
                                placeholder="Ej. 36"
                                classes="max-width-small"></currency-input>
                            €
                        </template>

                        <template v-else>
                            {{portionsMinimumPrice}} €
                        </template>
                    </div>
                </el-col>
            </el-row>

            <el-row v-if="cakeCustomizations.length" class="mt-5">
                <el-col>
                    <div class="mb-3 price-title">
                        Precios Extras
                    </div>

                    <div
                        v-for="(customization, key) in cakeCustomizations"
                        :key="key"
                        :class="[{ 'mt-3': (key!==0) }, 'd-flex', 'flex-row', 'justify-content-between', 'align-items-start']">
                        <div>
                            {{customization.name}}
                        </div>

                        <div class="ml-3 font-weight-bold">
                            <template v-if="byBudget">
                                N/A
                            </template>

                            <template v-else-if="customization.price==='0'">
                                Gratis
                            </template>

                            <template v-else-if="isValidCurrency(customization.price)">
                                <nobr>{{customization.price}} €</nobr>
                            </template>

                            <template v-else>
                                <nobr>Sin Precio</nobr>
                            </template>
                        </div>
                    </div>
                </el-col>
            </el-row>

            <el-row v-if="hasCustomizations" class="mt-5">
                <el-col>
                    <div class="mb-3 price-title">
                        Precios Personalizaciones
                    </div>

                    <div v-show="hasFillings" class="mb-2">
                        RELLENOS
                    </div>

                    <div v-show="hasFillings"
                        v-for="(filling, key) in cakeFillings"
                        :key="'filling-'+key"
                        :class="[{ 'mt-3': (key!==0) }, 'd-flex', 'flex-row', 'justify-content-between', 'align-items-start']">
                        <div>
                            {{filling.name}}
                        </div>

                        <div class="ml-3 font-weight-bold">
                            <template v-if="byBudget">
                                N/A
                            </template>

                            <template v-else-if="filling.price==='0'">
                                Gratis
                            </template>

                            <template v-else-if="isValidCurrency(filling.price)">
                                <nobr>{{filling.price}} €</nobr>
                            </template>

                            <template v-else>
                                <nobr>Sin Precio</nobr>
                            </template>
                        </div>
                    </div>

                    <div v-show="hasFlours" class="mt-4 mb-2">
                        HARINAS - BIZCOCHOS
                    </div>

                    <div v-show="hasFlours"
                        v-for="(flour, key) in cakeFlours"
                        :key="'flour-'+key"
                        :class="[{ 'mt-3': (key!==0) }, 'd-flex', 'flex-row', 'justify-content-between', 'align-items-start']">
                        <div>
                            {{flour.name}}
                        </div>

                        <div class="ml-3 font-weight-bold">
                            <template v-if="byBudget">
                                N/A
                            </template>

                            <template v-else-if="flour.price==='0'">
                                Gratis
                            </template>

                            <template v-else-if="isValidCurrency(flour.price)">
                                <nobr>{{flour.price}} €</nobr>
                            </template>

                            <template v-else>
                                <nobr>Sin Precio</nobr>
                            </template>
                        </div>
                    </div>

                    <div v-show="hasToppings" class="mt-4 mb-2">
                        COBERTURAS
                    </div>

                    <div v-show="hasToppings"
                        v-for="(topping, key) in cakeToppings"
                        :key="'topping-'+key"
                        :class="[{ 'mt-3': (key!==0) }, 'd-flex', 'flex-row', 'justify-content-between', 'align-items-start']">
                        <div>
                            {{topping.name}}
                        </div>

                        <div class="ml-3 font-weight-bold">
                            <template v-if="byBudget">
                                N/A
                            </template>

                            <template v-else-if="topping.price==='0'">
                                Gratis
                            </template>

                            <template v-else-if="isValidCurrency(topping.price)">
                                <nobr>{{topping.price}} €</nobr>
                            </template>

                            <template v-else>
                                <nobr>Sin Precio</nobr>
                            </template>
                        </div>
                    </div>

                  <div v-show="hasSweeteners" class="mt-4 mb-2">
                    ENDULZANTES
                  </div>

                  <div v-show="hasSweeteners"
                      v-for="(sweetener, key) in cakeSweeteners"
                      :key="'sweetener-'+key"
                      :class="[{ 'mt-3': (key!==0) }, 'd-flex', 'flex-row', 'justify-content-between', 'align-items-start']">
                    <div>
                      {{sweetener.name}}
                    </div>

                    <div class="ml-3 font-weight-bold">
                      <template v-if="byBudget">
                        N/A
                      </template>

                      <template v-else-if="sweetener.price==='0'">
                        Gratis
                      </template>

                      <template v-else-if="isValidCurrency(sweetener.price)">
                        <nobr>{{sweetener.price}} €</nobr>
                      </template>

                      <template v-else>
                        <nobr>Sin Precio</nobr>
                      </template>
                    </div>
                  </div>

                </el-col>
            </el-row>

            <el-row v-if="cakePortions.length" class="mt-5">
                <el-col>
                    <div class="mb-3 price-title">
                        Precios Porciones
                    </div>

                    <div
                        v-for="(portion, key) in cakePortions"
                        :key="key"
                        :class="[{ 'mt-3': (key!==0) }, 'd-flex', 'flex-row', 'justify-content-between', 'align-items-start']">
                        <div>
                            <nobr><i
                                class="el-icon-close icon-gray mr-2"
                                @click="removePortion(portion.portions)"></i>{{portion.portions}} Personas
                            </nobr>
                        </div>

                        <div class="ml-3 font-weight-bold">
                            <template v-if="byBudget">
                                N/A
                            </template>

                            <template v-else>
                                <nobr>{{portion.price}} €</nobr>
                            </template>
                        </div>
                    </div>
                </el-col>
            </el-row>

            <el-row v-if="(!!cakeShipping.type && !!cakeShipping.price) || (!!cakeShipping.type && cakeShipping.type==='IN_SHOP')" class="mt-5">
                <el-col>
                    <div class="mb-3 price-title">
                        Precio Envío
                    </div>

                    <div class="d-flex flex-row justify-content-between align-items-start">
                        <div>
                            <nobr>
                                <shipping-type :value="cakeShipping.type"></shipping-type>
                            </nobr>
                        </div>

                        <div v-if="!!cakeShipping.price" class="ml-3 font-weight-bold">
                            <template v-if="byBudget">
                                N/A
                            </template>

                            <template v-else>
                                <nobr>{{cakeShipping.price}} €</nobr>
                            </template>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </el-col>
    </el-row>
</template>

<script>
    import ShippingTypeSelector from '../ShippingTypeSelector';
    import ShippingType from '../ShippingType';
    import CurrencyInput from '../CurrencyInput';

    export default {
        name: "CakePricesForm",
        components: {
            ShippingTypeSelector,
            ShippingType,
            CurrencyInput
        },
        props: {
            doFormSubmit: {
                type: Boolean
            },
            initialCakeCustomizations: {
                type: Array,
                default: function () {
                    return []
                }
            },
            initialCakeFillings: {
                type: Array,
                default: function () {
                    return []
                }
            },
            initialCakeFlours: {
                type: Array,
                default: function () {
                    return []
                }
            },
            initialCakeToppings: {
                type: Array,
                default: function () {
                    return []
                }
            },
            initialCakeSweeteners: {
              type: Array,
              default: function () {
                return []
              }
            },
            initialCakePortions: {
                type: Array,
                default: function () {
                    return []
                }
            },
            initialCakeShipping: {
                type: Object,
                default: function () {
                    return {
                        type: null,
                        price: null
                    }
                }
            },
            initialByBudget: {
                type: Boolean,
                default: false
            },
            initialAllowsPayOnDelivery: {
                type: Boolean,
                default: false
            },
            initialAvailableForPickup: {
              type: Boolean,
              default: true
            },
            initialMinimumPrice: {
                type: Number
            },
            purchasable: {
              type: Boolean,
              default: true
            },
        },
        data() {
            let numberValidator = (rule, value, callback) => {
                if (!value && value !== 0) {
                    return callback(new Error('Campo obligatorio'));
                }
                setTimeout(() => {
                    if (!Number.isInteger(value)) {
                        callback(new Error('No es un valor numérico'));
                    } else if (value < 1) {
                        callback(new Error('Valor mínimo de 1'));
                    } else {
                        callback();
                    }
                }, 200);
            };

            let priceValidator = (rule, value, callback) => {
                if (!value && value !== 0) {
                    return callback(new Error('Campo obligatorio'));
                } else if (!/^\d+([.,]\d{1,2})?$/.test(value)) {
                    callback(new Error('No es un valor numérico'));
                } else if (value < 1) {
                    callback(new Error('Valor mínimo de 1'));
                } else {
                    callback();
                }
            };

            return {
                cakeCustomizations: this.initialCakeCustomizations,
                cakeFillings: this.initialCakeFillings,
                cakeFlours: this.initialCakeFlours,
                cakeToppings: this.initialCakeToppings,
                cakeSweeteners: this.initialCakeSweeteners,
                cakePortions: this.initialCakePortions,
                cakeShipping: {
                    type: null,
                    price: null
                },
                byBudget: false,
                allowsPayOnDelivery: false,
                notAvailableForPickup: false,
                minimumPrice: null,
                portion: {
                    portions: null,
                    price: null
                },
                portionRules: {
                    portions: [
                        {validator: numberValidator, trigger: 'blur'}
                    ],
                    price: [
                        {validator: priceValidator, trigger: 'blur'}
                    ]
                },
                shippingRules: {
                    type: [
                        {required: true, message: 'Campo obligatorio', trigger: 'change'}
                    ],
                    price: [
                        {validator: priceValidator, trigger: 'blur'}
                    ]
                },
                extrasModuleError: false,
                customizationsModuleError: false,
                portionsModuleError: false
            }
        },
        created() {
            this.cakeCustomizations = this.initialCakeCustomizations;
            this.cakePortions = this.initialCakePortions;
            this.cakeShipping = Object.assign({}, this.initialCakeShipping);
            this.byBudget = !this.purchasable || this.initialByBudget;
            this.allowsPayOnDelivery = this.initialAllowsPayOnDelivery;
            this.notAvailableForPickup = !this.initialAvailableForPickup
            this.minimumPrice = this.initialMinimumPrice;
        },
        computed: {
            portionsMinimumPrice() {
                return this.cakePortions.length
                    ? this.cakePortions
                        .map(portion => portion.price)
                        .reduce((minimumPrice, price) => (price < minimumPrice ? price : minimumPrice))
                    : "-";
            },
            hasCustomizations() {
                return this.hasFillings || this.hasFlours || this.hasToppings || this.hasSweeteners;
            },
            hasFillings() {
                return this.cakeFillings && this.cakeFillings.length > 0
            },
            hasFlours() {
                return this.cakeFlours && this.cakeFlours.length > 0
            },
            hasToppings() {
                return this.cakeToppings && this.cakeToppings.length > 0;
            },
            hasSweeteners() {
              return this.cakeSweeteners && this.cakeSweeteners.length > 0;
            }
        },
        methods: {
            addPortion() {
                this.$refs['portionPriceForm'].validate((valid) => {
                    if (valid) {
                        this.cakePortions.push(Object.assign({}, this.portion));
                        this.cakePortions.sort((a, b) => a.portions - b.portions);
                        this.resetPortionForm();
                    }
                });
            },
            removePortion(portions) {
                this.cakePortions = this.cakePortions.filter(portion => portion.portions !== portions);
            },
            resetCustomizationForm() {
                this.extrasModuleError = false;
            },
            resetPortionForm() {
                this.portion.portions = null;
                this.portion.price = null;
                this.portionsModuleError = false;
                this.$refs['portionPriceForm'].clearValidate();
            },
            resetShippingForm() {
                this.$refs['shippingPriceForm'].clearValidate();
            },
            resetAllForms() {
                this.resetCustomizationForm();
                this.resetPortionForm();
                this.resetShippingForm();
            },
            changeNotAvailableForPickup(value) {
              this.notAvailableForPickup = value;
              if (value && this.cakeShipping.type === 'IN_SHOP') {
                this.cakeShipping = {
                  type: null,
                  price: null
                }
              }
            },
            submitForm() {
                this.resetAllForms();

                if (this.byBudget) {
                    // send default form
                    this.$emit('submit', {
                        customization: this.initialCakeCustomizations,
                        fillings: this.initialCakeFillings,
                        flours: this.initialCakeFlours,
                        toppings: this.initialCakeToppings,
                        sweeteners: this.initialCakeSweeteners,
                        portions: [],
                        availableForPickup: !this.notAvailableForPickup,
                        shipping: {
                            type: null,
                            price: null
                        },
                        byBudget: true,
                        allowsPayOnDelivery: this.allowsPayOnDelivery,
                        minimumPrice: this.isValidCurrency(this.minimumPrice) ? this.minimumPrice : null
                    });
                } else {
                    // validate customizations
                    let validCustomizations = this.cakeCustomizations.reduce((isValid, customization) => {
                        return isValid
                            ? this.validCustomizationPrice(customization.price)
                            : false;
                    }, true);
                    this.extrasModuleError = !validCustomizations;

                    // validate fillings
                    let validFillings = this.hasFillings ? this.cakeFillings.reduce((isValid, filling) => {
                        return isValid
                            ? this.validCustomizationPrice(filling.price)
                            : false;
                    }, true) && this.cakeFillings.some((filling => filling.price == 0)) : true;

                    // validate flours
                    let validFlours = this.hasFlours ? this.cakeFlours.reduce((isValid, flour) => {
                        return isValid
                            ? this.validCustomizationPrice(flour.price)
                            : false;
                    }, true) && this.cakeFlours.some((flour => flour.price == 0)) : true;

                    // validate toppings
                    let validToppings = this.hasToppings ? this.cakeToppings.reduce((isValid, topping) => {
                        return isValid
                            ? this.validCustomizationPrice(topping.price)
                            : false;
                    }, true) && this.cakeToppings.some((topping => topping.price == 0)) : true;

                    // validate sweeteners
                    let validSweeteners = this.hasSweeteners ? this.cakeSweeteners.reduce((isValid, sweetener) => {
                      return isValid
                          ? this.validCustomizationPrice(sweetener.price)
                          : false;
                    }, true) && this.cakeSweeteners.some((sweetener => sweetener.price == 0)) : true;

                    this.customizationsModuleError = !validFillings || !validFlours || !validToppings || !validSweeteners;

                    // validate portions
                    let validPortions = !!this.cakePortions.length;
                    this.portionsModuleError = !validPortions;

                    // validate shipping
                    let validShipping;
                    this.$refs['shippingPriceForm'].validate((valid) => {
                        validShipping = !!valid;
                    });

                    if (validFillings && validFlours && validToppings && validSweeteners
                        && validCustomizations && validPortions && validShipping) {

                        this.$emit('submit', {
                            customization: this.cakeCustomizations,
                            fillings: this.cakeFillings,
                            flours: this.cakeFlours,
                            toppings: this.cakeToppings,
                            sweeteners: this.cakeSweeteners,
                            portions: this.cakePortions,
                            availableForPickup: !this.notAvailableForPickup,
                            shipping: this.cakeShipping,
                            byBudget: false,
                            allowsPayOnDelivery: this.allowsPayOnDelivery,
                            minimumPrice: null
                        });
                    } else {
                        this.$emit('not-valid');
                    }
                }
            },
            validCustomizationPrice(value) {
                return ((value || value === 0) && this.isValidCurrency(value) && value >= 0);
            },
            isValidCurrency(currency) {
                return (/^\d+([.,]\d{1,2})?$/.test(currency));
            }
        },
        watch: {
            doFormSubmit: function () {
                if (this.doFormSubmit) {
                    this.submitForm();
                }
            },
            byBudget: function () {
                this.resetAllForms();
            },
            initialCakeCustomizations: function () {
                this.cakeCustomizations = this.initialCakeCustomizations;
            },
            initialCakePortions: function () {
                this.cakePortions = this.initialCakePortions;
            },
            initialCakeShipping: function () {
                this.cakeShipping = Object.assign({}, this.initialCakeShipping);
            },
            initialByBudget: function () {
                this.byBudget = this.initialByBudget;
            },
            initialAllowsPayOnDelivery: function () {
                this.allowsPayOnDelivery = this.initialAllowsPayOnDelivery;
            },
            initialMinimumPrice: function () {
                this.minimumPrice = this.initialMinimumPrice;
            },
            'cakeShipping.type': function() {
                if (this.cakeShipping.type === 'IN_SHOP') {
                    this.cakeShipping.price = null;
                }
            }
        }
    }
</script>

<style scoped>
    >>> label {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
    }

    .module-error {
        color: #D89172;
        font-size: 12px;
        line-height: 1;
    }

    .info-legend {
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.01em;
        color: #493D35;
    }

    .module-disabled {
        opacity: 0.5;
    }

    .overview {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.02em;
        color: #493D35;
    }

    i.el-icon-close {
        cursor: pointer;
    }

    .price-title {
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        color: #9AA4B3;
    }
</style>

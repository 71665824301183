<template>
    <el-row>
        <el-col>
            <template v-if="Array.isArray(allergens) && allergens.length">
                <el-row>
                    <el-col class="list-title">
                        CONTIENE
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <div
                            v-for="(value, key) in allergens"
                            :key="key"
                            :class="[{'mt-2': key>0}, 'item ml-1 d-flex flex-row align-items-center']">
                            <i class="el-icon-close icon-gray mr-1" @click="removeAllergen(value)"></i>
                            <allergen :identifier="value"/>
                        </div>
                    </el-col>
                </el-row>
            </template>

            <template v-if="Array.isArray(traces) && traces.length">
                <el-row>
                    <el-col class="list-title">
                        TRAZAS DE
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <div
                            v-for="(value, key) in traces"
                            :key="key"
                            :class="[{'mt-2': key>0}, 'item ml-1 d-flex flex-row align-items-center']">
                            <i class="el-icon-close icon-gray mr-1" @click="removeTrace(value)"></i>
                            <allergen :identifier="value"/>
                        </div>
                    </el-col>
                </el-row>
            </template>

            <el-row class="mt-2">
                <el-col>
                    <el-button class="button-tag" @click="dialogVisible = true">
                        Añadir Alérgenos
                        <i class="el-icon-plus icon-gray"></i>
                    </el-button>

                    <el-dialog :visible.sync="dialogVisible" :before-close="handleModalClosed">
                        <el-row class="mx-4">
                            <el-col :span="12">
                                <div class="section-title mb-4">CONTIENE</div>
                                <allergen-selector v-model="tempAllergens"/>
                            </el-col>

                            <el-col :span="12">
                                <div class="section-title mb-4">TRAZAS DE</div>
                                <allergen-selector v-model="tempTraces"/>
                            </el-col>
                        </el-row>

                        <div slot="footer" class="dialog-footer">
                            <el-button type="text" @click="closeModal">Descartar cambios</el-button>
                            <el-button type="primary" @click="save()">Guardar</el-button>
                        </div>
                    </el-dialog>
                </el-col>
            </el-row>
        </el-col>
    </el-row>
</template>

<script>
    import Allergen from '../Allergen';
    import AllergenSelector from '../AllergenSelector';

    export default {
        name: "AllergenListEditable",
        components: {
            Allergen,
            AllergenSelector,
        },
        created() {
            this.fillAllergens(this.initialAllergens)
            this.fillTraces(this.initialTraces)
        },
        props: {
            initialAllergens: {
                type: Array,
                default: function () {
                    return []
                },
            },
            initialTraces: {
                type: Array,
                default: function () {
                    return []
                },
            }
        },
        data() {
            return {
                dialogVisible: false,
                allergens: [],
                tempAllergens: [],
                traces: [],
                tempTraces: [],
            }
        },
        methods: {
            fillAllergens(allergens) {
                this.allergens = allergens.map(allergen => allergen)
                this.resetTemporalAllergens()
            },
            fillTraces(traces) {
                this.traces = traces.map(trace => trace)
                this.resetTemporalTraces()
            },
            resetTemporalAllergens() {
                this.tempAllergens = this.allergens.map(allergen => allergen)
            },
            resetTemporalTraces() {
                this.tempTraces = this.traces.map(trace => trace)
            },
            removeAllergen(identifier) {
                this.allergens = this.allergens.filter(allergen => allergen !== identifier)
                this.resetTemporalAllergens()
                this.emitAllergens()
            },
            removeTrace(identifier) {
                this.traces = this.traces.filter(traces => traces !== identifier)
                this.resetTemporalTraces()
                this.emitTraces()
            },
            handleModalClosed(done) {
                this.resetTemporalAllergens()
                this.resetTemporalTraces()
                done()
            },
            closeModal() {
                this.resetTemporalAllergens()
                this.resetTemporalTraces()
                this.dialogVisible = false
            },
            save() {
                this.allergens = this.tempAllergens.map(allergen => allergen)
                this.traces = this.tempTraces.map(trace => trace)
                this.emitAllergens()
                this.emitTraces()
                this.dialogVisible = false
            },
            emitAllergens() {
                this.$emit('updated-allergens', this.allergens)
            },
            emitTraces() {
                this.$emit('updated-traces', this.traces)
            }
        },
        watch: {
            initialAllergens: function () {
                this.fillAllergens(this.initialAllergens)
            },
            initialTraces: function () {
                this.fillTraces(this.initialTraces)
            },
        }
    }
</script>

<style scoped>
    .item {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
    }

    .list-title {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #5D5039;
    }
</style>

<template>
    <el-checkbox-group v-model="selected" :min="1" @change="submit">
        <el-row>
            <el-col :span="12">
                <div v-for="(tag, index) in tags" :key="tag.uuid">
                    <el-checkbox v-if="index%2 === 0" :label="tag.uuid">{{tag.name}}</el-checkbox>
                </div>
            </el-col>

            <el-col :span="12">
                <div v-for="(tag, index) in tags" :key="tag.uuid">
                    <el-checkbox v-if="index%2 !== 0" :label="tag.uuid">{{tag.name}}</el-checkbox>
                </div>
            </el-col>
        </el-row>
    </el-checkbox-group>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: "TagsSelector",
        created() {
            if (this.value.length) {
                if (this.tags.length < 1) {
                    this.$store.dispatch('loadTags').then(() => {
                        this.fillSelectedFromUuids(this.value)
                    })
                } else {
                    this.fillSelectedFromUuids(this.value)
                }
            }
        },
        props: {
            value: {
                type: Array,
                default: function () {
                    return []
                }
            }
        },
        data() {
            return {
                selected: [],
            }
        },
        computed: {
            ...mapGetters([
                'tags'
            ])
        },
        methods: {
            fillSelectedFromUuids(uuidArray) {
                if (Array.isArray(uuidArray)) {
                    this.selected = uuidArray.filter(uuid => this.tags.some(tag => tag.uuid === uuid))
                } else {
                    this.selected = []
                }
            },
            submit() {
                this.$emit('input', this.selected)
            },
        },
        watch: {
            value: function () {
                this.fillSelectedFromUuids(this.value)
            },
        },
    }
</script>

<style scoped>

</style>
<template>
    <el-row>
        <el-col>
            <el-row>
                <el-col>
                    <div
                        v-for="(value, key) in tags"
                        :key="key"
                        :class="[{'mt-2': key>0}, 'item ml-1 d-flex flex-row align-items-center']">
                        <i class="el-icon-close icon-gray mr-1" @click="removeTag(value)"></i>
                        <tag :identifier="value"/>
                    </div>
                </el-col>
            </el-row>

            <el-row class="mt-2">
                <el-col>
                    <el-button class="button-tag" @click="dialogVisible = true">
                        Añadir Tags
                        <i class="el-icon-plus icon-gray"></i>
                    </el-button>

                    <el-dialog :visible.sync="dialogVisible" :before-close="handleModalClosed">
                        <el-row class="mx-4">
                            <el-col>
                                <tags-selector v-model="tempTags"/>
                            </el-col>
                        </el-row>

                        <div slot="footer" class="dialog-footer">
                            <el-button type="text" @click="closeModal">Descartar cambios</el-button>
                            <el-button type="primary" @click="save()">Guardar</el-button>
                        </div>
                    </el-dialog>
                </el-col>
            </el-row>
        </el-col>
    </el-row>
</template>

<script>
    import TagsSelector from '../TagsSelector'
    import Tag from '../Tag'

    export default {
        name: "TagListEditable",
        components: {
            TagsSelector,
            Tag,
        },
        created() {
            this.fillTags()
        },
        props: {
            value: {
                type: Array,
                default: function () {
                    return []
                }
            },
        },
        data() {
            return {
                dialogVisible: false,
                tags: [],
                tempTags: [],
            }
        },
        methods: {
            fillTags() {
                this.tags = this.value.map(tag => tag)
                this.resetTemporalTags()
            },
            resetTemporalTags() {
                this.tempTags = this.tags.map(tag => tag)
            },
            handleModalClosed(done) {
                this.resetTemporalTags()
                done()
            },
            closeModal() {
                this.resetTemporalTags()
                this.dialogVisible = false
            },
            removeTag(identifier) {
                this.tags = this.tags.filter(tag => tag !== identifier)
                this.resetTemporalTags()
                this.emitTags()
            },
            save() {
                this.tags = this.tempTags.map(tag => tag)
                this.emitTags()
                this.dialogVisible = false
            },
            emitTags() {
                this.$emit('input', this.tags)
            },
        },
        watch: {
            value: function () {
                this.fillTags()
            }
        },
    }
</script>

<style scoped>
    .item {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
    }
</style>
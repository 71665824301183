<template>
    <el-form
        ref="cakeForm"
        :model="cake"
        :rules="rules"
        size="medium"
        :status-icon="true"
        :inline-message="true"
        :hide-required-asterisk="true"
        label-position="top"
        @submit.prevent>
        <el-row :gutter="20">

            <el-col :span="8">

              <el-row>
                <el-col>
                  <el-form-item prop="purchasable">
                    <template slot="label">
                      <el-switch
                          class="mr-2"
                          :value="cake.purchasable"
                          @change="changePurchasable"/>
                      Disponible para pedidos
                    </template>
                  </el-form-item>
                </el-col>
              </el-row>

                <el-row class="mt-2">
                    <el-col>
                        <div class="section-title">
                            DESCRIPCIÓN GENERAL
                        </div>
                    </el-col>
                </el-row>

                <el-row class="mt-2">
                    <el-col>
                        <el-form-item prop="name">
                            <template slot="label">
                                Nombre*
                            </template>
                            <el-input
                                v-model="cake.name"
                                placeholder="Ej. La Raspeberry Chocolate"
                                class="max-width"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row class="mt-2">
                    <el-col>
                        <el-form-item prop="description">
                            <template slot="label">
                                Descripción*
                            </template>
                            <el-input
                                v-model="cake.description"
                                type="textarea"
                                :autosize="{ minRows: 3}"
                                maxlength="256"
                                placeholder="Máx 256 caracteres"
                                class="max-width">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row class="mt-2">
                    <el-col>
                        <el-form-item prop="processingTime">
                            <template slot="label">
                              Plazo de Elaboración (días)<span v-if="cake.purchasable">*</span>
                            </template>
                            <el-input
                                v-model.number="cake.processingTime"
                                placeholder="Ej. 3"
                                class="max-width-small"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row class="mt-2">
                    <el-col>
                        <el-form-item prop="preservation">
                            <template slot="label">
                                Conservación y caducidad<span v-if="cake.purchasable">*</span>
                            </template>
                            <el-input
                                v-model="cake.preservation"
                                type="textarea"
                                :autosize="{ minRows: 3}"
                                maxlength="256"
                                placeholder="Máx 256 caracteres"
                                class="max-width">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row class="mt-2">
                    <el-col>
                        <el-form-item prop="recommendations">
                            <template slot="label">
                                Recomendaciones
                            </template>
                            <el-input
                                v-model="cake.recommendations"
                                type="textarea"
                                :autosize="{ minRows: 3}"
                                maxlength="256"
                                placeholder="Máx 256 caracteres"
                                class="max-width">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row class="mt-2">
                    <el-col>
                        <el-form-item prop="dyes">
                            <template slot="label">
                                Colorantes y conservantes
                            </template>
                            <el-input
                                v-model="cake.dyes"
                                type="textarea"
                                :autosize="{ minRows: 3}"
                                maxlength="256"
                                placeholder="Máx 256 caracteres"
                                class="max-width">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row class="mt-2">
                    <el-col>
                        <el-form-item prop="allergens">
                            <template slot="label">
                                <label>Alérgenos<span v-if="cake.purchasable">*</span></label>
                            </template>
                            <allergen-list-editable
                                :initial-allergens="cake.allergens"
                                :initial-traces="cake.allergenTraces"
                                @updated-allergens="setAllergens"
                                @updated-traces="setAllergensTraces"/>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row class="mt-2">
                    <el-col>
                        <el-form-item prop="tags">
                            <template slot="label">
                                Tags*
                            </template>
                            <tag-list-editable v-model="cake.tags" @input="validateField('tags')"/>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-col>

            <el-col :span="8">
                <el-row>
                    <el-col>
                        <div class="section-title">
                            PERSONALIZACIÓN E INGREDIENTES
                        </div>
                    </el-col>
                </el-row>

                <el-row class="mt-2">
                    <el-col>
                        <el-form-item prop="customizations">
                            <template slot="label">
                                Extras
                            </template>
                            <extra-list-editable
                                v-model="cake.customizations"
                                placeholder="Añadir Personalización"
                                class="max-width"
                                @input="validateField('customizations')"></extra-list-editable>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row class="mt-2 mb-3">
                    <el-col>
                        <el-form-item prop="ingredients">
                            <template slot="label">
                                Ingredientes<span v-if="cake.purchasable">*</span>
                            </template>
                            <item-list-editable
                                v-model="cake.ingredients"
                                placeholder="Añadir Ingrediente"
                                class="max-width"
                                @input="validateField('ingredients')"></item-list-editable>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row class="mt-3">
                    <el-col>
                        <el-form-item prop="fillings">
                            <template slot="label">
                                <el-switch
                                    class="mr-2"
                                    :value="fillingsEnable"
                                    @change="enableFillings()"/>
                                Rellenos
                            </template>
                            <customization-list-editable
                                v-show="fillingsEnable"
                                v-model="cake.fillings"
                                placeholder="Añadir relleno"
                                class="max-width mt-1"
                                @input="validateField('fillings')"></customization-list-editable>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row class="mt-3">
                    <el-col>
                        <el-form-item prop="flours">
                            <template slot="label">
                                <el-switch
                                    class="mr-2"
                                    :value="floursEnable"
                                    @change="enableFlours"/>
                                Harinas - Bizcochos
                            </template>
                            <customization-list-editable
                                v-show="floursEnable"
                                v-model="cake.flours"
                                placeholder="Añadir harina"
                                class="max-width mt-1"
                                @input="validateField('flours')"></customization-list-editable>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row class="mt-3">
                    <el-col>
                        <el-form-item prop="toppings">
                            <template slot="label">
                                <el-switch
                                    class="mr-2"
                                    :value="toppingsEnable"
                                    @change="enableToppings"/>
                                Coberturas
                            </template>
                            <customization-list-editable
                                v-show="toppingsEnable"
                                v-model="cake.toppings"
                                placeholder="Añadir cobertura"
                                class="max-width mt-1"
                                @input="validateField('toppings')"></customization-list-editable>
                        </el-form-item>
                    </el-col>
                </el-row>

              <el-row class="mt-3">
                <el-col>
                  <el-form-item prop="sweeteners">
                    <template slot="label">
                      <el-switch
                          class="mr-2"
                          :value="sweetenersEnable"
                          @change="enableSweeteners"/>
                      Endulzantes
                    </template>
                    <customization-list-editable
                        v-show="sweetenersEnable"
                        v-model="cake.sweeteners"
                        placeholder="Añadir endulzante"
                        class="max-width mt-1"
                        @input="validateField('sweeteners')"></customization-list-editable>
                  </el-form-item>
                </el-col>
              </el-row>

            </el-col>

            <el-col :span="8">
                <el-row>
                    <el-col>
                        <div class="section-title">
                            IMAGEN DESTACADA
                        </div>
                    </el-col>

                    <el-row class="mt-3">
                        <el-col>
                            <el-form-item prop="base64Images">
                                <image-upload-base64-multiple
                                    v-model="cake.base64Images"
                                    :do-loading="imagesLoading"
                                    :show-required-error="showRequiredImageError"
                                    @input="validateImageField"/>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row class="mt-5">
                        <el-col>
                            <div class="legend">*Obligatorio</div>
                        </el-col>
                    </el-row>
                </el-row>
            </el-col>

        </el-row>
    </el-form>
</template>

<script>
    import ItemListEditable from '../ItemListEditable';
    import CustomizationListEditable from '../CustomizationListEditable';
    import ExtraListEditable from '../ExtraListEditable';
    import ImageUploadBase64Multiple from '../ImageUploadBase64Multiple';
    import {dialogs} from "../../constants/text-constants";
    import AllergenListEditable from '../AllergenListEditable';
    import TagListEditable from '../TagListEditable';

    export default {
        name: "CakeForm",
        props: {
            doFormSubmit: {
                type: Boolean
            },
            initialCake: {
                type: Object,
                default: function () {
                    return {
                        purchasable: true,
                        name: null,
                        description: null,
                        processingTime: null,
                        preservation: null,
                        recommendations: null,
                        dyes: null,
                        allergens: [],
                        allergenTraces: [],
                        customizations: [],
                        ingredients: [],
                        fillings: [],
                        flours: [],
                        toppings: [],
                        sweeteners: [],
                        tags: [],
                    }
                }
            },
            initialImages: {
                type: Array,
                default: function () {
                    return []
                }
            },
            imagesLoading: {
                type: Boolean,
                default: false
            }
        },
        components: {
            ItemListEditable,
            CustomizationListEditable,
            ExtraListEditable,
            ImageUploadBase64Multiple,
            AllergenListEditable,
            TagListEditable,
        },
        data() {
            return {
                cake: {
                    purchasable: null,
                    name: null,
                    description: null,
                    processingTime: null,
                    preservation: null,
                    recommendations: null,
                    dyes: null,
                    allergens: [],
                    allergenTraces: [],
                    customizations: [],
                    ingredients: [],
                    fillings: [],
                    flours: [],
                    toppings: [],
                    sweeteners: [],
                    base64Images: [],
                    tags: []
                },
                rules: {},
                showRequiredImageError: false,
                fillingsEnable: this.initialCake.fillings.length > 0,
                floursEnable: this.initialCake.flours.length > 0,
                toppingsEnable: this.initialCake.toppings.length > 0,
                sweetenersEnable: this.initialCake.sweeteners.length > 0,
            }
        },
        created() {
            this.fillCakeWithInitialValues();
            this.fillCakeImagesWithInitialValues();
            this.loadValidatorsRules();
        },
        methods: {
            loadValidatorsRules() {
              let numberOfDaysValidator = (rule, value, callback) => {
                if (!value) {
                  return callback(new Error('Campo obligatorio'));
                }
                setTimeout(() => {
                  if (!Number.isInteger(value)) {
                    callback(new Error('No es un valor numérico'));
                  } else if (value < 1) {
                    callback(new Error('Valor mínimo de 1'));
                  } else {
                    callback();
                  }
                }, 200);
              };

              let requiredArrayValidator = (rule, value, callback) => {
                if (!value || !Array.isArray(value) || value.length < 1) {
                  return callback(new Error('Campo obligatorio'));
                } else {
                  return callback();
                }
              };

              let notRequired = (rule, value, callback) => {
                return callback();
              };

              let requiredAllergensValidator = (rule, value, callback) => {

                let notExistAllergens = !this.cake.allergens || !Array.isArray(this.cake.allergens) || this.cake.allergens.length < 1;
                let notExistAllergenTraces = !this.cake.allergenTraces || !Array.isArray(this.cake.allergenTraces) || this.cake.allergenTraces.length < 1;

                if (notExistAllergens && notExistAllergenTraces) {
                  return callback(new Error('Campo obligatorio'));
                } else {
                  return callback();
                }
              };

              let requiredFillingsValidator = (rule, value, callback) => {
                if (this.fillingsEnable && (!value || !Array.isArray(value) || value.length < 2)) {
                  return callback(new Error('Debe introducir al menos dos opciones'));
                } else {
                  return callback();
                }
              };

              let requiredFloursValidator = (rule, value, callback) => {
                if (this.floursEnable && (!value || !Array.isArray(value) || value.length < 2)) {
                  return callback(new Error('Debe introducir al menos dos opciones'));
                } else {
                  return callback();
                }
              };

              let requiredToppingsValidator = (rule, value, callback) => {
                if (this.toppingsEnable && (!value || !Array.isArray(value) || value.length < 2)) {
                  return callback(new Error('Debe introducir al menos dos opciones'));
                } else {
                  return callback();
                }
              };

              let requiredSweetenersValidator = (rule, value, callback) => {
                if (this.sweetenersEnable && (!value || !Array.isArray(value) || value.length < 2)) {
                  return callback(new Error('Debe introducir al menos dos opciones'));
                } else {
                  return callback();
                }
              };

              let imagesValidator = (rule, value, callback) => {
                if (Array.isArray(value) && value.length > 3) {
                  return callback(new Error('El máximo de imágenes es 3'));
                } else {
                  return callback();
                }
              };
              this.rules = {
                name: [
                  {required: true, message: 'Campo obligatorio', trigger: 'blur'},
                  {min: 3, message: 'Longitud mínima de 3 caracteres', trigger: 'blur'}
                ],
                description: [
                  {required: true, message: 'Campo obligatorio', trigger: 'blur'},
                  {min: 3, max: 256, message: 'Longitud entre 3 y 256 caracteres', trigger: 'blur'}
                ],
                preservation: [
                  {required: this.cake.purchasable, message: 'Campo obligatorio', trigger: 'blur'},
                  {min: 3, max: 256, message: 'Longitud entre 3 y 256 caracteres', trigger: 'blur'}
                ],
                recommendations: [
                  {min: 3, max: 256, message: 'Longitud entre 3 y 256 caracteres', trigger: 'blur'}
                ],
                fillings: [
                  {validator: requiredFillingsValidator, trigger: 'blur'}
                ],
                flours: [
                  {validator: requiredFloursValidator, trigger: 'blur'}
                ],
                toppings: [
                  {validator: requiredToppingsValidator, trigger: 'blur'}
                ],
                sweeteners: [
                  {validator: requiredSweetenersValidator, trigger: 'blur'}
                ],
                base64Images: [
                  {validator: imagesValidator}
                ],
                tags: [
                  {validator: requiredArrayValidator, trigger: 'blur'}
                ],
                processingTime: [
                  {validator: this.cake.purchasable ? numberOfDaysValidator : notRequired, trigger: 'blur'}
                ],
                ingredients: [
                  {validator: this.cake.purchasable ? requiredArrayValidator : notRequired, trigger: 'blur'}
                ],
                allergens: [
                  {validator: this.cake.purchasable ? requiredAllergensValidator : notRequired, trigger: 'blur'}
                ]
              }
            },
            changePurchasable(purchasable) {
              this.cake.purchasable = purchasable;
              this.loadValidatorsRules();
              this.$refs['cakeForm'].validate(() => {});
            },
            fillCakeWithInitialValues() {
                this.cake = Object.assign({}, this.cake, this.initialCake);
            },
            fillCakeImagesWithInitialValues() {
                this.cake.base64Images = this.initialImages;
            },
            validateField(prop) {
                this.$refs['cakeForm'].validateField(prop);
            },
            validateImageField() {
                this.showRequiredImageError = false;
                this.$refs['cakeForm'].validateField('base64Images', (message) => {
                    if (message) {
                        this.showRequiredImageError = true;
                    }
                });
            },
            submitForm() {
                this.$refs['cakeForm'].validate((valid) => {
                    if (valid) {
                        this.$emit('submit', this.cake);
                    } else {
                        this.$emit('not-valid');
                        return false;
                    }
                });
            },
            enableFillings() {
                if (!this.fillingsEnable || this.cake.fillings.length === 0) {
                    this.fillingsEnable = !this.fillingsEnable;
                } else {

                    this.confirmDisableCustomization()
                    .then(() => {
                        this.fillingsEnable = false;
                        this.cake.fillings = [];
                        this.validateField('fillings');
                    }).catch(() => {});
                }
                this.validateField('fillings');
            },
            enableFlours() {
                if (!this.floursEnable || this.cake.flours.length === 0) {
                    this.floursEnable = !this.floursEnable;
                } else {

                    this.confirmDisableCustomization()
                        .then(() => {
                            this.floursEnable = false;
                            this.cake.flours = [];
                            this.validateField('flours');
                        }).catch(() => {});
                }
                this.validateField('flours');
            },
            enableToppings() {
                if (!this.toppingsEnable || this.cake.toppings.length === 0) {
                    this.toppingsEnable = !this.toppingsEnable;
                } else {

                    this.confirmDisableCustomization()
                        .then(() => {
                            this.toppingsEnable = false;
                            this.cake.toppings = [];
                            this.validateField('toppings');
                        }).catch(() => {});
                }
                this.validateField('toppings');
            },
            enableSweeteners() {
              if (!this.sweetenersEnable || this.cake.sweeteners.length === 0) {
                this.sweetenersEnable = !this.sweetenersEnable;
              } else {
                this.confirmDisableCustomization()
                    .then(() => {
                      this.sweetenersEnable = false;
                      this.cake.sweeteners = [];
                      this.validateField('sweeteners');
                    }).catch(() => {});
              }
              this.validateField('sweeteners');
            },
            confirmDisableCustomization() {
                return this.$confirm(dialogs.cake.disableCustomization.text, {
                    confirmButtonText: dialogs.cake.disableCustomization.confirm,
                    cancelButtonText: dialogs.cake.disableCustomization.cancel,
                    dangerouslyUseHTMLString: true,
                });
            },
            setAllergens(allergens) {
                this.cake.allergens = allergens;
                this.$refs['cakeForm'].validateField('allergens');
            },
            setAllergensTraces(traces) {
                this.cake.allergenTraces = traces;
                this.$refs['cakeForm'].validateField('allergens');
            },
        },
        watch: {
            doFormSubmit: function () {
                if (this.doFormSubmit) {
                    this.submitForm();
                }
            },
            initialCake: function () {
                this.fillCakeWithInitialValues();
            },
            initialImages: function () {
                this.fillCakeImagesWithInitialValues();
            },
            'cake.description': function () {
                if (!!this.cake.description && this.cake.description.length > 256) {
                    this.cake.description = this.cake.description.substring(0, 256);
                }
            },
            'cake.preservation': function () {
                if (!!this.cake.preservation && this.cake.preservation.length > 256) {
                    this.cake.preservation = this.cake.preservation.substring(0, 256);
                }
            },
            'cake.recommendations': function () {
                if (!!this.cake.recommendations && this.cake.recommendations.length > 256) {
                    this.cake.recommendations = this.cake.recommendations.substring(0, 256);
                }
            }
        },
    }
</script>

<style scoped>
    .el-form-item {
        margin-bottom: 0.5rem; /* equal to 'mb-2' class */
    }

    >>> label {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
    }

    .legend {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: #5D5039;
    }
</style>
